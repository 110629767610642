(function(module) {
try {
  module = angular.module('todoPartials');
} catch (e) {
  module = angular.module('todoPartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/partials/invitation.html',
    '\'<!-- Navigation -->\n' +
    '<nav id="navbar" class="navbar navbar-custom navbar-fixed-top" role="navigation">\n' +
    '    <div class="container">\n' +
    '        <div class="navbar-header">\n' +
    '            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-main-collapse">\n' +
    '                Menu <i class="fa fa-bars"></i>\n' +
    '            </button>\n' +
    '            <a class="navbar-brand page-scroll" href="#page-top">\n' +
    '                <i class="fa fa-birthday-cake"></i>\n' +
    '                <span class="light" translate="nav.start"></span>\n' +
    '            </a>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Collect the nav links, forms, and other content for toggling -->\n' +
    '        <div id="navbar-links" class="collapse navbar-collapse navbar-right navbar-main-collapse">\n' +
    '            <ul class="nav navbar-nav">\n' +
    '                <!-- Hidden li included to remove active class from about link when scrolled up past about section -->\n' +
    '                <li class="hidden">\n' +
    '                    <a href="#page-top"></a>\n' +
    '                </li>\n' +
    '                <li>\n' +
    '                    <a class="page-scroll" href="#information" translate="nav.information"></a>\n' +
    '                </li>\n' +
    '                <li>\n' +
    '                    <a class="page-scroll" href="#location" translate="nav.location"></a>\n' +
    '                </li>\n' +
    '                <li>\n' +
    '                    <a class="page-scroll" href="#contact" translate="nav.contact"></a>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '\n' +
    '            <ul class="nav navbar-nav">\n' +
    '                <li>\n' +
    '                    <a class="page-scroll" ng-click="vm.changeLanguage(\'de\');">de</a>\n' +
    '                </li>\n' +
    '                <li>\n' +
    '                    <a class="page-scroll" ng-click="vm.changeLanguage(\'en\');">en</a>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '        </div>\n' +
    '        <!-- /.navbar-collapse -->\n' +
    '    </div>\n' +
    '    <!-- /.container -->\n' +
    '</nav>\n' +
    '\n' +
    '<!-- Intro Header -->\n' +
    '<header class="intro">\n' +
    '    <div class="intro-body">\n' +
    '        <div class="container">\n' +
    '            <div class="row">\n' +
    '                <div class="col-xs-12"><h1 class="brand-heading">Andy\'s</h1>\n' +
    '                    <img src="/images/thirty.png" class="thirty_logo"/>\n' +
    '                    <h1 class="brand-heading">birthday party</h1>\n' +
    '                    <a href="#information" class="btn btn-circle page-scroll">\n' +
    '                        <i class="fa fa-angle-double-down animated"></i>\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</header>\n' +
    '\n' +
    '<!-- About Section -->\n' +
    '<section id="information" class="container content-section text-center">\n' +
    '    <div class="row">\n' +
    '        <div class="col-lg-6">\n' +
    '            <h2 translate="when"></h2>\n' +
    '            <p>\n' +
    '                <span class="weekday" translate="information.weekday"></span><br/>\n' +
    '                <span class="month" translate="information.month"></span><br/>\n' +
    '                <span class="day" translate="information.day"></span>&nbsp;&nbsp;&nbsp;\n' +
    '                <span class="year" translate="information.year"></span></p>\n' +
    '            <p>\n' +
    '                <span class="time">16:00 - Open End</span>\n' +
    '            </p>\n' +
    '        </div>\n' +
    '        <div class="col-lg-6">\n' +
    '            <h2 translate="where"></h2>\n' +
    '            <p>Pfadiheim<br/>Emme<br/>3400 Burgdorf</p>\n' +
    '        </div>\n' +
    '        <div class="col-lg-10 col-lg-offset-1">\n' +
    '            <h2 translate="what"></h2>\n' +
    '            <p translate="information.text1"></p>\n' +
    '            <p translate="information.text2"></p>\n' +
    '        </div>\n' +
    '        <div class="col-lg-12">\n' +
    '            <a href="#location" class="btn btn-circle page-scroll">\n' +
    '                <i class="fa fa-angle-double-down animated"></i>\n' +
    '            </a>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</section>\n' +
    '\n' +
    '\n' +
    '<section id="location" class="container content-section text-center">\n' +
    '    <div class="row">\n' +
    '        <div class="col-xs-10 col-xs-offset-1">\n' +
    '            <h2 translate="location.header"></h2>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div id="map"></div>\n' +
    '    <div class="row">\n' +
    '        <div class="col-lg-12">\n' +
    '            <a href="#contact" class="btn btn-circle page-scroll">\n' +
    '                <i class="fa fa-angle-double-down animated"></i>\n' +
    '            </a>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</section>\n' +
    '\n' +
    '<!-- Contact Section -->\n' +
    '<section id="contact" class="container content-section text-center">\n' +
    '    <div class="row">\n' +
    '        <div class="col-lg-8 col-lg-offset-2">\n' +
    '            <h2 translate="contact.header"></h2>\n' +
    '            <p translate="contact.info"></p>\n' +
    '\n' +
    '            <ul>\n' +
    '                <li>\n' +
    '                    <span class="info" translate="contact.toSend.number"></span>\n' +
    '                    <br/>\n' +
    '                    <span class="description" translate="contact.toSend.numberDesc"></span>\n' +
    '                </li>\n' +
    '                <li>\n' +
    '                    <span class="info" translate="contact.toSend.bring"></span><br/>\n' +
    '                    <span class="description" translate="contact.toSend.bringDesc"></span>\n' +
    '                </li>\n' +
    '                <li>\n' +
    '                    <span class="info" translate="contact.toSend.sleep"></span><br/>\n' +
    '                    <span class="description" translate="contact.toSend.sleepDesc"></span>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '            <a href="mailto:{{ \'contact.email\' | translate}}" class="btn btn-default btn-lg">\n' +
    '                <i class="fa fa-envelope-o fa-fw"></i>\n' +
    '                <span translate="contact.sendEmail"></span>\n' +
    '            </a>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '        <div class="col-lg-8 col-lg-offset-2">\n' +
    '            <p>&nbsp;</p>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '        <div class="col-lg-8 col-lg-offset-2"><h2 translate="contact.details"></h2>\n' +
    '            <p>\n' +
    '                <i class="fa fa-mobile-phone fa-fw"></i>\n' +
    '                <span translate="contact.phone"></span>\n' +
    '                <br/>\n' +
    '                <i class="fa fa-envelope-open-o fa-fw"></i>\n' +
    '                <span translate="contact.email"></span>\n' +
    '            </p>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</section>\n' +
    '\n' +
    '<!-- Footer -->\n' +
    '<footer class="navbar footer-custom navbar-fixed-bottom">\n' +
    '    <div class="container">\n' +
    '        <div class="row">\n' +
    '            <div class="col-xs-8 col-xs-offset-2">\n' +
    '                <svg id="banner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1290.25 221.82"><title>Banner</title>\n' +
    '                    <g id="Landschaft">\n' +
    '                        <path d="M-5.31,887c28.44-.72,56.88-1.7,85.33-2.12,13.71-.2,27.63.14,41,3.69,6.35,1.69,13.56,4.15,17.47,9.83,3.39,4.92,3.32,12-1.61,15.8-1.52,1.18.61,3.29,2.12,2.12,8.25-6.41,4.92-18.35-2.32-24C126.1,884,110,882.75,97.11,882.09c-16.8-.86-33.73.16-50.54.59L-5.31,884c-1.93,0-1.93,3,0,3Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M125.83,927.18c15-15.7,38.74-8.58,57.15-5,10.26,2,20.82,3.12,31,.15,10.5-3.06,21-10.21,32.07-5.26,8.24,3.67,14.35,8.88,23.9,8.23,8.4-.57,16.22-4.09,24.24-6.34a39.85,39.85,0,0,1,13.64-1.86c5.57.41,10.3,3.31,15.43,5.19,9.77,3.59,18.9,0,28.28-3.07,12.66-4.07,24.78-2.54,37.69-.77,10.72,1.47,21.49,2.49,31.93-1,1.82-.62,1-3.51-.8-2.89-20.65,7-41.93-4.45-62.88-.06-11.62,2.44-22.16,9.32-34.21,4.65-4.55-1.77-8.74-4.14-13.65-4.81a34.92,34.92,0,0,0-12,.71c-9.09,1.93-17.65,6.37-27,7.23a25.51,25.51,0,0,1-15.08-3.33c-3.89-2.12-7.58-4.7-11.89-5.89-9.5-2.62-18.53,2.28-27.26,5.35-12,4.23-24,2.71-36.25.19-18.54-3.82-41.63-9-56.47,6.54-1.33,1.4.78,3.52,2.12,2.12Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M432.09,919.55c-4-1.58-5.44-5.94-5-10,.56-4.63,3.68-7.94,7.61-10.17,7.62-4.31,17.5-5.28,26-6.53,20.18-3,40.63-3.67,61-4.72l121.41-6.26c81.11-4.18,162.21-8.93,243.36-12.33,45.47-1.91,91-2.92,136.5-1.44,1.93.06,1.93-2.94,0-3-86.52-2.8-173.07,3.11-259.44,7.56-86.85,4.48-173.72,8.75-260.56,13.45-12,.65-24,1.42-35.91,2.89-10,1.23-20.88,2.28-30.3,6.1-6.13,2.48-11.7,6.95-12.63,13.85-.77,5.74,1.58,11.31,7.12,13.47,1.8.7,2.58-2.2.8-2.89Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1024.55,867.89q101-.45,202-.68l54-.21a1.5,1.5,0,0,0,0-3q-101,.45-202,.68l-54,.21a1.5,1.5,0,0,0,0,3Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M730.26,818c-8.25-.88-24.16-6.28-18.63-17.41,2.52-5.07,8.37-10.38,6.06-16.59-1.81-4.86-6.61-6.74-5.85-12.74.72-5.68,3.25-11.45,5.84-16.49,4.43-8.63,11.13-14.72,20.44-17.7a1.51,1.51,0,0,0-.4-2.95q-1.65-2.07.44-2.42a16.35,16.35,0,0,1,2.31-.94c.91-.36,1.84-.65,2.72-1.08a11.75,11.75,0,0,0,4.63-3.6,6.9,6.9,0,0,0,.32-7.85,1.51,1.51,0,0,0-2.05-.54c-2.68,1.91-4.5,4.73-3.05,8.06,2.6,6,10.49,3.86,13.78,8.79,1.47,2.2,2.29,4.84,3.85,7a43.07,43.07,0,0,0,6.29,7,57.79,57.79,0,0,0,6.86,5.27c.87.58,3.51,1.52,4,2.48,1,1.9-.32,2.06-.13,3.49.25,1.93,1,2.21,2.28,3.68,2.69,3,.7,3.19-.82,5.55a4.2,4.2,0,0,0-.85,3.54c.54,2,3.55,2.39,2.52,4.61-.48,1.05-3.58,1.88-4.48,2.71a3.81,3.81,0,0,0-1.55,3.22c.18,2.82,4.12,3.57,3.48,6.73-.85,4.21-7.4,5.5-10.76,5.7a1.52,1.52,0,0,0-1.06,2.56c12.6,11.58-16.86,21-23.93,23.4-1.82.61-1,3.51.8,2.89,9.06-3.06,40.68-14.23,25.25-28.41l-1.06,2.56c4.6-.28,10-1.69,12.69-5.85a6.61,6.61,0,0,0,1.15-4.2c-.23-2.15-2.28-2.92-2.74-4.63-1-3.84,6.27-3.62,6.41-7.52.07-2-1.68-2.06-2.44-3.44-1.83-3.3,1.25-3.7,2.05-6.29a3.71,3.71,0,0,0-.63-3.61c-.35-.51-1-.77-1.32-1.23-1.8-2.25-1.2-1.6-1.19-4,0-4.6-5.73-6.13-9-8.55-6.06-4.54-9.56-9.84-13.15-16.3a8.06,8.06,0,0,0-4.72-4c-3.32-1.21-14-3.75-7.08-8.7l-2.05-.54c4.42,7.84-9.88,7.11-12.08,11.9-1.49,3.23,1.44,5.14,4.25,5.48l-.4-2.95a36.69,36.69,0,0,0-21.69,18A62.2,62.2,0,0,0,709,769.94c-.87,4.72.31,7.85,3.23,11.5,2.77,3.45,3.69,5.73,1.63,9.78-1.1,2.16-2.62,4.09-3.85,6.17-2,3.34-3.47,7-2.13,10.82,3,8.67,14.34,11.94,22.39,12.8,1.92.2,1.9-2.8,0-3Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M735.36,803.51a7.5,7.5,0,0,1-3.92-4,8.1,8.1,0,0,1,.71-3.45c.08-1.06.4-1.37,0-2.41-.65-1.71-2.45-2.2-3.69-3.33-4-3.61,4.88-4.46,3.11-9-.58-1.49-4.14-2.3-3.46-3.48.92-1.58,4.2-1.29,5.08-3.43a3.23,3.23,0,0,0-1.08-3.67,10.28,10.28,0,0,0-3.46-1.77c-.5-.18-7-2.11-3.71-2.61,1.9-.29,1.09-3.18-.8-2.89s-4.24,1.53-3.75,4c.55,2.75,4.12,3.05,6.17,4,6.2,2.89-1,2.82-2.18,5.8-.85,2.14,1,3.09,2.44,4.11,1.81,1.24,2,1,.34,2.72-.93,1-2.26,1.62-2.92,2.85-1,1.93-.34,3.43,1.14,4.83.88.82,3.32,1.6,3.75,2.68s-.86,3.18-.83,4.33c.1,3.43,2.83,5.72,5.53,7.34a1.5,1.5,0,0,0,1.51-2.59Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M753.27,785.49c2.38.47,5.94.31,7.36-2.12,1.67-2.86-1.15-5.31-3.21-6.93l-.66,2.51c2.45-.61,7-1.4,7.83-4.37.72-2.58-1.62-4-3.71-4.53l.9,2.2,4.2-5.53a1.51,1.51,0,0,0-.9-2.2c-3.18-.85-6.43-4.16-1.9-6.37a1.51,1.51,0,0,0-.36-2.74A37.77,37.77,0,0,1,747.32,750c-1.62-1.06-3.13,1.54-1.51,2.59A39.67,39.67,0,0,0,762,758.29l-.36-2.74c-7.11,3.47-3.71,10.15,2.62,11.85l-.9-2.2-4.2,5.53a1.51,1.51,0,0,0,.9,2.2c3.72.95-3.53,3-4.12,3.11a1.51,1.51,0,0,0-.66,2.51q3.32,1.6-1.23,4c-1.89-.37-2.69,2.52-.8,2.89Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1075.49,861.66c0-40.38-1.48-80.78-2.17-121.15-.15-9-.31-18-.32-27a1.5,1.5,0,0,0-3,0c0,40.38,1.48,80.78,2.17,121.15.15,9,.31,18,.32,27a1.5,1.5,0,0,0,3,0Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1070.82,712.76a585.18,585.18,0,0,0,210.81-4l-1.16-2.74c-4,2.69-5.18,11.13-5.85,15.31a58.48,58.48,0,0,0,4.58,32.95c.77,1.76,3.36.24,2.59-1.51a55.09,55.09,0,0,1-4.27-30.63c.49-3.15,1.5-11.51,4.47-13.51a1.51,1.51,0,0,0-1.16-2.74,581,581,0,0,1-209.22,4c-1.89-.31-2.7,2.58-.8,2.89Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1070.67,768c34.66-7.48,69.73-13.06,105.14-15.36A624.15,624.15,0,0,1,1280.5,755c1.92.2,1.9-2.8,0-3a623.72,623.72,0,0,0-106.5-2.21c-35.06,2.38-69.8,7.94-104.13,15.35-1.89.41-1.09,3.3.8,2.89Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1071.41,767.67a13.66,13.66,0,0,0,13.52-7.17c2.95-5.71,1-13.83.24-19.9-.55-4.44-5.88-34.59-14.85-28.52-1.59,1.08-.09,3.68,1.51,2.59,2.6-1.76,7.48,12.06,7.88,13.43a84.75,84.75,0,0,1,2.65,14.15c.52,4.5,1.74,10.12.72,14.59-1.23,5.38-6.43,8.34-11.68,7.83-1.92-.19-1.91,2.81,0,3Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1101.08,723.69a36.27,36.27,0,0,1,6.07-.12c3.17.17,5.56.85,7.22,2.05a8.3,8.3,0,0,1,3.13,5.3,10,10,0,0,1-.49,6.37,8.7,8.7,0,0,1-8.74,3.9c1.32,5.36,2,8.12,3,11.1-1.1.22-1.65.33-2.73.56C1105.6,743.29,1103.24,733.47,1101.08,723.69Zm6.32,13.95a7.63,7.63,0,0,0,2.56.18c3.62-.23,5.28-2.85,4.46-6.82-.79-3.8-3.3-5.21-6.79-5.36a12,12,0,0,0-2.93.14C1105.48,729.49,1106.13,732.36,1107.4,737.64Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1125,742.5c-.18,3.54-.24,5.31-.58,7.66l-3,.42c1.07-7.62.14-19.39,2.59-25.45l3.79.4c4.12,6.62,7.79,16.35,12.51,22.85l-3.07.3a48.36,48.36,0,0,1-4-6.67C1129.92,742.18,1128.27,742.28,1125,742.5Zm7-3.09c-1.56-3-2.36-4.71-3.85-7.67-.85-1.68-1.45-3.1-2-4.32h-.07c-.16,1.19-.29,2.61-.45,4.16-.36,3-.46,4.71-.65,8.05C1127.79,739.52,1129.2,739.48,1132,739.41Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1139.41,726.87c1.5-.08,3.66,0,5.69.09,3.15.17,5.14.56,6.56,1.5a4.63,4.63,0,0,1,2,3.49,5.13,5.13,0,0,1-3.19,5.48l0,.1c1.71.54,2.88,2.07,3.72,4.22a28.29,28.29,0,0,0,2.41,5.26l-2.52.2a21.26,21.26,0,0,1-2.21-4.53c-1.15-2.84-2.44-4-5-4.07l-2.64.07c.79,4.07,1.26,6.23,2,9.17l-2.76.24A184.14,184.14,0,0,1,1139.41,726.87Zm4.44,9.51,2.9,0c3,0,4.53-1.79,4.23-4.1-.34-2.61-2.44-3.53-5.51-3.7a15.56,15.56,0,0,0-2.88,0C1142.92,731.22,1143.22,733,1143.85,736.39Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1161.53,728.81c-2.57.08-3.81.18-6.39.24,0-.77-.06-1.13-.08-1.81,6.36-.18,9.43-.39,15.82-.44,0,.71,0,1.08,0,1.85-2.64,0-4,0-6.56.09a154.1,154.1,0,0,0,1.26,17.61l-2.53.17A152.71,152.71,0,0,1,1161.53,728.81Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1179.83,745.59c-.29-3-.4-4.74-.57-8.09-3-4.5-4.46-7-7.09-10.7h3.18c1.18,1.78,1.81,2.8,3.08,4.88.87,1.42,1.56,2.61,2.3,4h.07a42,42,0,0,1,2.09-4c1.22-2.07,1.85-3.06,3.09-4.87h3.21a103.52,103.52,0,0,0-7.08,10.65c.16,3.34.27,5.06.56,8.06Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1201.46,728.45c-2.66.08-4,.1-6.66.14,0-.77,0-1.15,0-1.89,6.49-.11,9.56-.21,16.2-.72,0,.78,0,1.17,0,2-2.73.22-4,.29-6.63.4.05,6.42-.23,10.19.23,16.51l-2.64,0C1201.37,738.63,1201.57,734.81,1201.46,728.45Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1216.59,725.49c-.11,6.95-.83,12.3-.77,19.29H1213c-.17-7,.5-12,.56-19Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1243.64,735.31c.15-3.54.31-7.79.56-10.42h-.12c-1.1,2.56-2.51,5.78-4.18,9.25a109.32,109.32,0,0,1-5.61,10.73l-2.54-.06a90.28,90.28,0,0,1-3.44-9.92c-1-3.09-1.8-5.8-2.4-8h-.08c-.22,2.4-.71,6.08-1.18,9.33-.55,3.72-.84,5.61-1.18,8.58l-3,0c.76-6.94,1.93-13,2.88-20l4.32-.46a51.47,51.47,0,0,1,3.78,9.61c.85,2.77,1.52,5.32,2,7.51h.12c.83-2.12,2-4.71,3.49-7.8,2.34-4.75,3.56-7.48,5.68-11.08l4.39-.55c.25,6.95-.49,16.13-.46,23.37l-3.29-.15C1243.37,742.13,1243.47,739.77,1243.64,735.31Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1265.2,734.08c-3.42,0-5.28,0-9.13.1-.36,4.11-.56,6.27-.85,9.51,4.32.26,6.41.41,10.26.8-.07,1-.1,1.53-.15,2.37-5-.59-7.83-.81-13.56-1.16.64-7.34,1.73-17.34,2.09-24.51,5.44-.7,8-1.05,12.52-1.65,0,.82,0,1.29,0,2.32L1257,723c-.17,2.8-.32,4.6-.62,8.18,3.84-.19,5.69-.27,9.08-.38C1265.34,732.08,1265.29,732.75,1265.2,734.08Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M730.51,875.27c3.37-2.65,3.3-10.19,3.44-14.19.25-6.9,0-13.87.05-20.78,0-3.82.13-7.58.46-11.39.18-2.11-.53-8.22,1-9.57,0,0,3.36-.36,3.37-.35.89.75.54,3.77.58,4.67.16,3.85.35,7.71.53,11.56.35,7.32.71,14.64,1.24,21.94.29,3.93.33,7.92,3.09,11.1,1,1.14,5.23,4.21,4.92,4.75s-3.42.59-4,.69c-3.91.61-8.48,1.64-12.46,1.05-.94-.14-1.35,1.31-.4,1.45,5.13.76,12.67-.19,17.62-1.82a.76.76,0,0,0,.52-.92c-1.41-3.37-4.13-4.8-6-7.77-2.53-4-1.89-10.51-2.16-15.06-.63-10.61-2.33-22-1.3-32.61.05-.53-.52-.79-.95-.72a48.06,48.06,0,0,1-6.15.62.76.76,0,0,0-.75.75c.89,8.9-.58,18.38-.65,27.35,0,4.08-.07,8.15,0,12.23,0,3.8.47,13.26-3,16-.76.6.31,1.65,1.06,1.06Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1283.49,861.66c0-42.13-.29-84.26-.43-126.4q0-13.88-.06-27.76a1.5,1.5,0,0,0-3,0c0,42.13.29,84.26.43,126.4q0,13.88.06,27.76a1.5,1.5,0,0,0,3,0Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                    </g>\n' +
    '                    <g id="Velo_1" data-name="Velo 1">\n' +
    '                        <path d="M481,877.42a30,30,0,0,1,0-7.63c.38-2.79,2.41-6.17,2.19-8.85.12,1.43,2.37,1.45,2.25,0-.27-3.28-3.78-.1-4.51,1.19-2.52,4.44-2.77,10.37-2.19,15.3.17,1.42,2.42,1.44,2.25,0Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M484.92,865.78c9.08.72,6.68,17.45.59,20.63-3.23,1.69-8.16,1.51-10-1.85s-2.15-7.65,1-10.4c1.09-1-.51-2.55-1.59-1.59-4,3.52-3.59,9.15-1.1,13.49,2.33,4.09,7.48,4.31,11.67,2.79,9.59-3.48,11.28-24.38-.57-25.32-1.44-.11-1.44,2.14,0,2.25Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M484.17,858a43.81,43.81,0,0,0,.95-7.9,1.14,1.14,0,0,0-1.92-.8l-.16.15c-1,1,.55,2.6,1.59,1.59l.16-.15-1.92-.8a40.42,40.42,0,0,1-.87,7.31c-.3,1.41,1.87,2,2.17.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M472.15,853.57c2.89.61,3.63.53,4.73-2,1-2.39,4.06-2.14,6.59-2.53,1.78-.27,3.84-1,5.6-.77l1.48-.17q3.25,1.61-1.21,3.23c-1.27-.7-2.41,1.25-1.14,1.94,4.79,2.63,6.91-2.37,5.5-6.65a1.13,1.13,0,0,0-.79-.79c-4-.77-9.77,1-13.73,1.64-1.53.27-3.31.26-4.2,1.72-.76,1.24,0,2.73-2.24,2.24-1.41-.3-2,1.87-.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M457.52,876.34c4.42-4.64,7.49-10.09,12.59-14.15.94-.75-.1-2.11-1.09-1.88l-.26.06c-1.41.33-.81,2.5.6,2.17l.26-.06-1.09-1.88c-5.12,4.08-8.15,9.5-12.59,14.15-1,1,.59,2.64,1.59,1.59Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M457,876.63a97.09,97.09,0,0,1,15.54-5.08,1.13,1.13,0,0,0,.67-1.65c-.9-1.18-1.86-2.32-2.76-3.51-1.27-1.67-1.5-3.2-2-5.21a1.13,1.13,0,0,0-2.17.6c.47,1.86.59,3.53,1.73,5.12,1,1.42,2.21,2.74,3.28,4.13l.67-1.65a97.09,97.09,0,0,0-15.54,5.08c-1.33.52-.75,2.7.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M470.28,862c2.51-1,5-2,7.44-3.13,2-.94,4.76-1.83,6.46-3.29,1.1-.94-.5-2.53-1.59-1.59-1.5,1.28-4.19,2.11-6,2.94-2.27,1-4.57,2-6.9,2.91-1.34.52-.76,2.69.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M473.1,870c3.9-3.47,6.91-7.91,10.31-11.85.94-1.09-.64-2.69-1.59-1.59-3.4,3.94-6.41,8.37-10.31,11.85-1.08,1,.51,2.55,1.59,1.59Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M467.08,860.41a6.83,6.83,0,0,1-.57-2.73,2.57,2.57,0,0,0-.44-1.65l-.4,1.54a3.36,3.36,0,0,0,1.09-1.18c.38-.66-.08-1.81-1-1.69a4.37,4.37,0,0,1-1.19-.09,12.17,12.17,0,0,0-3,.27l.87,2.06c1.83-1.1,3.85-1.93,5.75-2.9,1.29-.66.15-2.6-1.14-1.94-1.9,1-3.92,1.8-5.75,2.9a1.13,1.13,0,0,0,.87,2.06c1.25-.2,2.4.06,3.58-.09l-1-1.69-.28.37a1.14,1.14,0,0,0-.4,1.54c.35.45.11,1.66.24,2.25a11.51,11.51,0,0,0,.77,2.14c.57,1.33,2.51.18,1.94-1.14Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M459.6,867.24c9.08.72,6.68,17.45.59,20.63-3.23,1.69-8.16,1.51-10-1.85s-2.15-7.65,1-10.4c1.09-1-.51-2.55-1.59-1.59-4,3.52-3.59,9.15-1.1,13.49,2.33,4.09,7.48,4.31,11.67,2.79,9.59-3.48,11.28-24.38-.57-25.32-1.44-.11-1.44,2.14,0,2.25Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                    </g>\n' +
    '                    <g id="Mandli_stehend" data-name="Mandli stehend">\n' +
    '                        <path d="M99,817.26c-2.64,1.19-4.84,3.17-5.37,6.13-.38,2.1.2,5.67,2.88,5.91s4.91-2.31,6-4.43c1.26-2.56,1.4-5.54-.76-7.66-1-1-2.63.58-1.59,1.59,1.5,1.47,1.18,3.51.27,5.21-.68,1.28-2.06,3.28-3.64,2.64s-1-3.23-.34-4.49a7.53,7.53,0,0,1,3.72-2.95c1.32-.6.17-2.54-1.14-1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M98.86,831.94a52.46,52.46,0,0,0,11.38-5.8,1.14,1.14,0,0,0,.23-1.77c-2.84-2.87-6.78-3.12-10.07-5.18-1.23-.77-2.36,1.17-1.14,1.94,3.09,1.94,7,2.16,9.61,4.83l.23-1.77a50,50,0,0,1-10.84,5.58c-1.35.49-.77,2.67.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M95,831.65c-2.1,2.26-2.19,4.55-2.76,7.51-.7,3.6-1.65,7.2-2.67,10.71h2.17l-.08-.41c-.29-1.42-2.46-.82-2.17.6l.08.41a1.13,1.13,0,0,0,2.17,0c.8-2.77,1.48-5.57,2.14-8.37s.66-6.65,2.71-8.86c1-1.06-.6-2.66-1.59-1.59Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M91.5,831.86a42.06,42.06,0,0,0-7.87,5.06,1.13,1.13,0,0,0,0,1.59,58.75,58.75,0,0,0,5,4.13c1.17.86,2.29-1.09,1.14-1.94a53.55,53.55,0,0,1-4.57-3.78v1.59a39.54,39.54,0,0,1,7.41-4.7c1.3-.64.16-2.58-1.14-1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M89.2,852.47c1.1,2.89,3.34,6.48,3.65,9.54.27,2.68-2,6.53-2.58,9.09-.52,2.36-2.2,7.92.78,9.22,1.31.57,2.46-1.37,1.14-1.94-1.7-.74.26-6.88.65-8.3.6-2.17,2.38-4.58,2.48-6.82.16-3.56-2.69-8.08-3.95-11.37-.51-1.34-2.69-.76-2.17.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M87.92,853.8c-1.25,8-3.32,16.4-3,24.58a1.15,1.15,0,0,0,.83,1.08l2.11.47c1.41.31,2-1.86.6-2.17-3.56-.79,1.06-19.79,1.62-23.36.22-1.42-1.95-2-2.17-.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                    </g>\n' +
    '                    <g id="Mandli_wassersprung" data-name="Mandli wassersprung">\n' +
    '                        <path d="M133.81,887.24l-.85-3.43-1.08,1.42c6.72-.62,14.59-.28,21-2.5,6-2.07,10.84-7.79,15.31-12,1.06-1-.54-2.58-1.59-1.59-4.56,4.29-9.81,10.44-16.07,11.9-6,1.39-12.56,1.36-18.65,1.93a1.14,1.14,0,0,0-1.08,1.42l.85,3.43a1.13,1.13,0,0,0,2.17-.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M168.63,870.79c9.13-2.71,17.35-2,26.57-.21,1.41.27,2-1.9.6-2.17-9.56-1.84-18.3-2.6-27.76.21a1.13,1.13,0,0,0,.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M194.84,870.67a15.08,15.08,0,0,0-3,5.83,1.15,1.15,0,0,0,.79,1.38q12.32,3.71,24.9,6.32l.3-2.21-1,0a1.13,1.13,0,0,0,0,2.25l1,0c1.23,0,1.58-1.94.3-2.21q-12.6-2.61-24.9-6.32l.79,1.38a10.12,10.12,0,0,1,2.39-4.84c.89-1.12-.69-2.73-1.59-1.59Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M198.71,872.14c1.73.12,6,.69,6.64,2.74l.29-1.09c-1.59,1.45-7.75-2.35-8-2,.68-1.28-1.26-2.41-1.94-1.14-1.39,2.59,2.9,3.94,4.61,4.66,2.25.95,4.85,1.88,6.89,0a1.11,1.11,0,0,0,.29-1.09c-1-3.26-5.87-4.19-8.81-4.39-1.45-.1-1.44,2.15,0,2.25Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M198.45,870.92c4.06.88,8.84,1.29,12.51,3.31a83.59,83.59,0,0,1,8.39,5.93c1.17.85,2.3-1.1,1.14-1.94-3-2.21-6.11-5-9.56-6.54-3.65-1.65-8-2.09-11.88-2.94-1.41-.31-2,1.86-.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M165.86,869c-6.45,1.74-13.77,4.85-20.5,4.31-3.66-.29-7.32-.81-10.95-1.32-3.89-.55-1.48.45-6.2,2-1.37.45-.78,2.62.6,2.17,2.79-.92,3.8-1.86,6.9-1.74,3.46.13,6.91,1,10.39,1.21,6.75.34,13.91-2.72,20.36-4.46a1.13,1.13,0,0,0-.6-2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                    </g>\n' +
    '                    <g id="Mandli_schwimmend_1" data-name="Mandli schwimmend 1">\n' +
    '                        <path d="M265.55,920.69c-4.63-4-5.69-12.74-7.37-18.37a1.14,1.14,0,0,0-1.08-.83l-7.92,1c-1.42.18-1.44,2.43,0,2.25l7.92-1-1.08-.83c1.84,6.17,2.82,14.89,7.95,19.36,1.09.95,2.68-.64,1.59-1.59Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M267.32,918.68c-1.4-3.75.1-8.05-.38-12s-1.52-7.77-1.05-11.82a1.13,1.13,0,0,0-1.12-1.12l-7.68.25c-1.44,0-1.45,2.3,0,2.25l7.68-.25-1.12-1.12c-.54,4.64.93,8.81,1.22,13.4.24,3.76-1.06,7.38.3,11,.5,1.34,2.68.76,2.17-.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M269,921.08A22.89,22.89,0,0,1,286.94,903c.47-.1.27-.82-.2-.72a23.6,23.6,0,0,0-18.46,18.59c-.09.47.63.67.72.2Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M256.14,915.52c-4.54-5.41-11.66-4.75-18-3.82-.48.07-.27.79.2.72,6.06-.9,12.89-1.59,17.26,3.62.31.37.84-.16.53-.53Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M291.71,902.87a.38.38,0,0,0,0-.75.38.38,0,0,0,0,.75Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M231.87,912.65a.38.38,0,0,0,0-.75.38.38,0,0,0,0,.75Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M274.35,900.43c1.56-2.41,3.21-4.75,5-7,1.54-1.93,3.13-4.1,5.67-4.66.47-.11.27-.83-.2-.72-2.42.54-3.95,2.33-5.46,4.18a88.91,88.91,0,0,0-5.66,7.83c-.26.41.39.78.65.38Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M289.83,887.44a.38.38,0,0,0,0-.75.38.38,0,0,0,0,.75Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                    </g>\n' +
    '                    <g id="Mandli_schwimmend_2" data-name="Mandli schwimmend 2">\n' +
    '                        <path d="M325.72,918.59a27.9,27.9,0,0,1,7.49-3.53c1.78-.51,7.43-2.19,8.77-.26q1.47.73-.47,1.16a16.44,16.44,0,0,1-2.15,1,25.31,25.31,0,0,1-4,1.34c-3.12.74-6.77,1.15-9.82,0-1.35-.52-1.94,1.65-.6,2.17,4.33,1.66,9.8.68,14-.88,2.08-.77,6.51-1.95,6-4.8-.58-3-4.82-3.07-7.16-2.85a29.06,29.06,0,0,0-13.16,4.73c-1.21.79-.08,2.73,1.14,1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M321.86,918q12.33-4.83,24.8-9.31c4.8-1.72,8.21-.07,12.82,1.1A86.88,86.88,0,0,0,370,911.69c1.43.18,1.42-2.07,0-2.25a80.77,80.77,0,0,1-13.26-2.76c-3.55-1.07-5.25-2-8.83-.83-9,2.87-17.85,6.52-26.65,10-1.33.52-.75,2.7.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                    </g>\n' +
    '                    <g id="Mandli_auf_Velo_1" data-name="Mandli auf Velo 1">\n' +
    '                        <path d="M438.85,856.63c4.06.79,9.58,2.41,13.69,1.4,5.09-1.26,10.3-4.64,15.06-6.83,1.31-.61.17-2.55-1.14-1.94-4.17,1.92-8.31,4.13-12.58,5.8-4.64,1.81-9.68.34-14.44-.59-1.41-.28-2,1.89-.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M437,855a5.3,5.3,0,0,0-1.74,2.28c-.26.48-.5,1-.77,1.44a3.39,3.39,0,0,1-.55.77,1.13,1.13,0,0,0,1.14,1.94,5.3,5.3,0,0,0,1.74-2.28c.26-.48.5-1,.77-1.44a3.39,3.39,0,0,1,.55-.77A1.13,1.13,0,0,0,437,855Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M466.7,852.3a38.7,38.7,0,0,1,6.08,7.74c1.89,3.33,1.55,4.05-.38,6.92-3.16,4.7-5,10.48-7.11,15.68a1.13,1.13,0,0,0,.79,1.38l3.69.88c1.41.34,2-1.83.6-2.17l-3.69-.88.79,1.38c1.63-3.93,3.1-8,5-11.79,1.09-2.21,4.11-4.58,4.49-6.87.74-4.43-5.95-11.1-8.64-13.87-1-1-2.6.55-1.59,1.59Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M468.08,849.77c-.25-.33,1.55-2.71,1.84-3.1a22.07,22.07,0,0,1,3.34-3.54,37.06,37.06,0,0,1,7.53-4.87c1.29-.66.15-2.6-1.14-1.94a35.48,35.48,0,0,0-9.72,6.87c-1.39,1.46-5.39,5.59-3.8,7.72.86,1.15,2.81,0,1.94-1.14Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M483.79,836.19a29.69,29.69,0,0,1,6.07-2.8,24.25,24.25,0,0,1,2.59-.7c.7-.14,3.36-1,1.24.16-3.83,2.11-8.86,2.64-13.14,2.62a1.13,1.13,0,0,0,0,2.25,34.34,34.34,0,0,0,11-1.64c1.64-.54,5.95-1.48,5.7-3.92-.53-5.34-12.67,1-14.58,2.08a1.13,1.13,0,0,0,1.14,1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M478.5,837.38c-2.67.19-3.76,1.83-4.68,4.16-1.17,3-4.63,5.32-4.18,8.8a1.16,1.16,0,0,0,1.13,1.13,21,21,0,0,0,5.21-.51c1.41-.31.82-2.48-.6-2.17a18.53,18.53,0,0,1-4.61.43l1.13,1.13c-.34-2.65,2.37-4.29,3.42-6.47.78-1.62,1-4.08,3.19-4.24,1.44-.1,1.45-2.35,0-2.25Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M479.42,839.8c1.14,3.14,2.45,7,4.43,9.66a2.59,2.59,0,0,0,2.29,1.18,69.52,69.52,0,0,0,7.69-.28,1.13,1.13,0,0,0,.57-2.1l-.13-.08a1.13,1.13,0,0,0-1.14,1.94l.13.08.57-2.1-5.29.19c-1.82.07-2.59.38-3.44-1.29a68.92,68.92,0,0,1-3.51-7.81c-.49-1.35-2.66-.77-2.17.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M437.41,861.12a26.17,26.17,0,0,1-6.17,4.92.38.38,0,0,0,.38.65,26.83,26.83,0,0,0,6.32-5c.33-.35-.2-.89-.53-.53Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M439.91,861.86a38.65,38.65,0,0,1-9.74,6.92c-.43.22-.05.86.38.65a39.27,39.27,0,0,0,9.89-7c.35-.34-.18-.87-.53-.53Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M445.4,863a13.76,13.76,0,0,1-7.82,6.62c-.46.15-.26.88.2.72a14.53,14.53,0,0,0,8.26-7c.23-.43-.42-.8-.65-.38Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                    </g>\n' +
    '                    <g id="Mandli_auf_Velo_2" data-name="Mandli auf Velo 2">\n' +
    '                        <path d="M608,870.42a30,30,0,0,1,0-7.63c.38-2.79,2.41-6.17,2.19-8.85.12,1.43,2.37,1.45,2.25,0-.27-3.28-3.78-.1-4.51,1.19-2.52,4.44-2.77,10.37-2.19,15.3.17,1.42,2.42,1.44,2.25,0Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M611.92,858.78c9.08.72,6.68,17.45.59,20.63-3.23,1.69-8.16,1.51-10-1.85s-2.15-7.65,1-10.4c1.09-1-.51-2.55-1.59-1.59-4,3.52-3.59,9.15-1.1,13.49,2.33,4.09,7.48,4.31,11.67,2.79,9.59-3.48,11.28-24.38-.57-25.32-1.44-.11-1.44,2.14,0,2.25Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M611.17,851a43.81,43.81,0,0,0,.95-7.9,1.14,1.14,0,0,0-1.92-.8l-.16.15c-1,1,.55,2.6,1.59,1.59l.16-.15-1.92-.8a40.42,40.42,0,0,1-.87,7.31c-.3,1.41,1.87,2,2.17.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M577.52,869.34c4.42-4.64,7.49-10.09,12.59-14.15.94-.75-.1-2.11-1.09-1.88l-.26.06c-1.41.33-.81,2.5.6,2.17l.26-.06-1.09-1.88c-5.12,4.08-8.15,9.5-12.59,14.15-1,1,.59,2.64,1.59,1.59Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M577,869.63a97.09,97.09,0,0,1,15.54-5.08,1.13,1.13,0,0,0,.67-1.65c-.9-1.18-1.86-2.32-2.76-3.51-1.27-1.67-1.5-3.2-2-5.21a1.13,1.13,0,0,0-2.17.6c.47,1.86.59,3.53,1.73,5.12,1,1.42,2.21,2.74,3.28,4.13l.67-1.65a97.09,97.09,0,0,0-15.54,5.08c-1.33.52-.75,2.7.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M590.28,855c4-1,8.05-2.07,12-3.25,2.95-.88,6.55-1.52,9.25-3,1.27-.7.14-2.64-1.14-1.94-2.92,1.6-7,2.27-10.2,3.21-3.5,1-7,1.94-10.55,2.82a1.13,1.13,0,0,0,.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M594.2,863.22c5.8-3.3,10.82-7.9,16.17-11.85,1.15-.85,0-2.8-1.14-1.94-5.36,4-10.37,8.54-16.17,11.85a1.13,1.13,0,0,0,1.14,1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M587.08,853.41a6.83,6.83,0,0,1-.57-2.73,2.57,2.57,0,0,0-.44-1.65l-.4,1.54a3.36,3.36,0,0,0,1.09-1.18c.38-.66-.08-1.81-1-1.69a4.37,4.37,0,0,1-1.19-.09,12.17,12.17,0,0,0-3,.27l.87,2.06c1.83-1.1,3.85-1.93,5.75-2.9,1.29-.66.15-2.6-1.14-1.94-1.9,1-3.92,1.8-5.75,2.9a1.13,1.13,0,0,0,.87,2.06c1.25-.2,2.4.06,3.58-.09l-1-1.69-.28.37a1.14,1.14,0,0,0-.4,1.54c.35.45.11,1.66.24,2.25a11.51,11.51,0,0,0,.77,2.14c.57,1.33,2.51.18,1.94-1.14Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M579.6,860.24c9.08.72,6.68,17.45.59,20.63-3.23,1.69-8.16,1.51-10-1.85s-2.15-7.65,1-10.4c1.09-1-.51-2.55-1.59-1.59-4,3.52-3.59,9.15-1.1,13.49,2.33,4.09,7.48,4.31,11.67,2.79,9.59-3.48,11.28-24.38-.57-25.32-1.44-.11-1.44,2.14,0,2.25Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M607.36,845a11.87,11.87,0,0,0,7.51-1,1.13,1.13,0,0,0,.56-1l0-.64a1.13,1.13,0,0,0-.83-1.08,10.83,10.83,0,0,0-3.94-.05c-1.43.23-.82,2.4.6,2.17a7.63,7.63,0,0,1,2.74.05l-.83-1.08,0,.64.56-1a9.27,9.27,0,0,1-5.77.79c-1.42-.26-2,1.91-.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M584.95,846.08a26.07,26.07,0,0,1,20.46-16.48c1.43-.23.82-2.39-.6-2.17a28.26,28.26,0,0,0-22,18.05c-.52,1.35,1.66,1.94,2.17.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M607.35,829.05c2.62-2.14,7.67-3.78,10.75-1.55l-.56-1c0,.64-4.77,1.75-5.33,1.87a23.48,23.48,0,0,1-6.37.44c-1.45-.08-1.44,2.17,0,2.25,2.06.11,15.8-.76,13.85-5-.87-1.88-4.82-1.87-6.49-1.73a13.22,13.22,0,0,0-7.45,3.1c-1.12.92.48,2.5,1.59,1.59Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M585.09,846.26c4.55.33,8.86,2,13.41,2.37l-1-1.69c-3.41,5.37-7.64,10.94-9.11,17.27a1.14,1.14,0,0,0,.79,1.38l4,1a1.13,1.13,0,0,0,.6-2.17l-4-1,.79,1.38c1.41-6.08,5.6-11.57,8.89-16.73a1.14,1.14,0,0,0-1-1.69c-4.55-.33-8.86-2-13.41-2.37-1.44-.11-1.44,2.15,0,2.25Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M601.53,830.93a102.49,102.49,0,0,0-5.08,12.52c-.27.9.58,1.5,1.38,1.38a61.88,61.88,0,0,1,12.07-.56V842h-.5a1.13,1.13,0,0,0,0,2.25h.5a1.13,1.13,0,0,0,0-2.25,64.85,64.85,0,0,0-12.66.64l1.38,1.38a98,98,0,0,1,4.86-12c.59-1.31-1.35-2.46-1.94-1.14Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M603,831.74a22.47,22.47,0,0,0-.2,8.76,1.15,1.15,0,0,0,1.08.83q4.89.27,9.76.74l-1.08-.83V841l1-.56-.33,0,1.08.83-.41.71c1.43-.22.82-2.39-.6-2.17a1.47,1.47,0,0,0-1.17,2.06,1.17,1.17,0,0,0,1.08.83,1.52,1.52,0,0,0,1.53-2.06,1.17,1.17,0,0,0-1.08-.83q-4.87-.47-9.76-.74l1.08.83a19.49,19.49,0,0,1,.2-7.56c.23-1.42-1.93-2-2.17-.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M594.45,824.42q-8.43,1.64-17,2.73c-.47.06-.48.81,0,.75q8.62-1.1,17.15-2.76c.47-.09.27-.82-.2-.72Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M576.82,835.36q-4.75.86-9.54,1.36c-.48,0-.48.8,0,.75q4.9-.51,9.74-1.39c.47-.09.27-.81-.2-.72Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M567.73,847.44l-9.26,1.44c-.48.07-.27.8.2.72l9.26-1.44c.48-.07.27-.8-.2-.72Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M559.66,861.71l-8.71,1.17c-.47.06-.48.81,0,.75l8.71-1.17c.47-.06.48-.81,0-.75Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M557,871.18q-3.18.53-6.38.89c-.47.05-.48.8,0,.75q3.3-.37,6.58-.91c.47-.08.27-.8-.2-.72Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                    </g>\n' +
    '                    <g id="Velo_2" data-name="Velo 2">\n' +
    '                        <path d="M743,864.42a30,30,0,0,1,0-7.63c.38-2.79,2.41-6.17,2.19-8.85.12,1.43,2.37,1.45,2.25,0-.27-3.28-3.78-.1-4.51,1.19-2.52,4.44-2.77,10.37-2.19,15.3.17,1.42,2.42,1.44,2.25,0Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M746.92,852.78c9.08.72,6.68,17.45.59,20.63-3.23,1.69-8.16,1.51-10-1.85s-2.15-7.65,1-10.4c1.09-1-.51-2.55-1.59-1.59-4,3.52-3.59,9.15-1.1,13.49,2.33,4.09,7.48,4.31,11.67,2.79,9.59-3.48,11.28-24.38-.57-25.32-1.44-.11-1.44,2.14,0,2.25Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M746.17,845a43.81,43.81,0,0,0,.95-7.9,1.14,1.14,0,0,0-1.92-.8l-.16.15c-1,1,.55,2.6,1.59,1.59l.16-.15-1.92-.8a40.42,40.42,0,0,1-.87,7.31c-.3,1.41,1.87,2,2.17.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M734.15,840.57c2.89.61,3.63.53,4.73-2,1-2.39,4.06-2.14,6.59-2.53,1.78-.27,3.84-1,5.6-.77l1.48-.17q3.25,1.61-1.21,3.23c-1.27-.7-2.41,1.25-1.14,1.94,4.79,2.63,6.91-2.37,5.5-6.65a1.13,1.13,0,0,0-.79-.79c-4-.77-9.77,1-13.73,1.64-1.53.27-3.31.26-4.2,1.72-.76,1.24,0,2.73-2.24,2.24-1.41-.3-2,1.87-.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M719.52,863.34c4.42-4.64,7.49-10.09,12.59-14.15.94-.75-.1-2.11-1.09-1.88l-.26.06c-1.41.33-.81,2.5.6,2.17l.26-.06-1.09-1.88c-5.12,4.08-8.15,9.5-12.59,14.15-1,1,.59,2.64,1.59,1.59Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M719,863.63a97.09,97.09,0,0,1,15.54-5.08,1.13,1.13,0,0,0,.67-1.65c-.9-1.18-1.86-2.32-2.76-3.51-1.27-1.67-1.5-3.2-2-5.21a1.13,1.13,0,0,0-2.17.6c.47,1.86.59,3.53,1.73,5.12,1,1.42,2.21,2.74,3.28,4.13l.67-1.65a97.09,97.09,0,0,0-15.54,5.08c-1.33.52-.75,2.7.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M732.28,849c2.51-1,5-2,7.44-3.13,2-.94,4.76-1.83,6.46-3.29,1.1-.94-.5-2.53-1.59-1.59-1.5,1.28-4.19,2.11-6,2.94-2.27,1-4.57,2-6.9,2.91-1.34.52-.76,2.69.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M735.1,857c3.9-3.47,6.91-7.91,10.31-11.85.94-1.09-.64-2.69-1.59-1.59-3.4,3.94-6.41,8.37-10.31,11.85-1.08,1,.51,2.55,1.59,1.59Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M729.08,847.41a6.83,6.83,0,0,1-.57-2.73,2.57,2.57,0,0,0-.44-1.65l-.4,1.54a3.36,3.36,0,0,0,1.09-1.18c.38-.66-.08-1.81-1-1.69a4.37,4.37,0,0,1-1.19-.09,12.17,12.17,0,0,0-3,.27l.87,2.06c1.83-1.1,3.85-1.93,5.75-2.9,1.29-.66.15-2.6-1.14-1.94-1.9,1-3.92,1.8-5.75,2.9a1.13,1.13,0,0,0,.87,2.06c1.25-.2,2.4.06,3.58-.09l-1-1.69-.28.37a1.14,1.14,0,0,0-.4,1.54c.35.45.11,1.66.24,2.25a11.51,11.51,0,0,0,.77,2.14c.57,1.33,2.51.18,1.94-1.14Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M721.6,854.24c9.08.72,6.68,17.45.59,20.63-3.23,1.69-8.16,1.51-10-1.85s-2.15-7.65,1-10.4c1.09-1-.51-2.55-1.59-1.59-4,3.52-3.59,9.15-1.1,13.49,2.33,4.09,7.48,4.31,11.67,2.79,9.59-3.48,11.28-24.38-.57-25.32-1.44-.11-1.44,2.14,0,2.25Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                    </g>\n' +
    '                    <g id="Läufer_1" data-name="Läufer 1">\n' +
    '                        <path d="M824.93,838.47c5.08,2.75,9.14,6.92,14,10l-.4-1.54c-1.39,2.95-4.23,4.77-5.93,7.53-2,3.29-3.28,7-5.07,10.47a1.15,1.15,0,0,0,.67,1.65l4,1.53c1.36.51,1.94-1.66.6-2.17l-4-1.53.67,1.65c1.78-3.41,3-7.21,5.07-10.47,1.74-2.76,4.52-4.55,5.93-7.53a1.16,1.16,0,0,0-.4-1.54c-4.87-3.09-8.9-7.25-14-10-1.27-.69-2.41,1.25-1.14,1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M823,838.09c-4.19,3.56-8.74,8.8-13.92,10.68s-11,1.92-16.34,2.63a1.13,1.13,0,0,0-.5,1.88l1.61,2c.67,1.28,2.62.15,1.94-1.14a4.45,4.45,0,0,0-2-2.41l-.5,1.88c5.62-.76,12.35-.71,17.58-3s9.47-7.28,13.68-10.86c1.11-.94-.49-2.52-1.59-1.59Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M824.8,835.84a78.93,78.93,0,0,1,6.38-11.25c1.91-2.77,5.26-5.43,6.53-8.5a1.12,1.12,0,0,0-.52-1.27l-.13-.07a1.16,1.16,0,0,0-1.54.4l-.07.1c-.85,1.18,1.11,2.3,1.94,1.14l.07-.1-1.54.4.13.07-.52-1.27c-.58,1.4-2.47,2.83-3.41,4-1.14,1.46-2.24,3-3.28,4.49a79.27,79.27,0,0,0-6,10.7c-.61,1.3,1.33,2.45,1.94,1.14Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M831.49,817q-5.57,2.59-11,5.54a1.14,1.14,0,0,0,.27,2.06l10.75,4.19c1.35.53,1.94-1.65.6-2.17l-10.75-4.19.27,2.06q5.39-2.95,11-5.54c1.31-.61.17-2.55-1.14-1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M835.34,818.84a10.39,10.39,0,0,0-.47,3.79c.1,1.26.9,2.6.66,3.85a1.14,1.14,0,0,0,1.08,1.42,54.47,54.47,0,0,0,13.69-2.52c1.37-.44.79-2.61-.6-2.17a52.06,52.06,0,0,1-13.09,2.44l1.08,1.42a5,5,0,0,0-.13-2.61,8.87,8.87,0,0,1-.05-5,1.13,1.13,0,0,0-2.17-.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M836.66,813.35a8,8,0,0,1,1.95-4.7,12.79,12.79,0,0,1,1.45-1.56,4.08,4.08,0,0,1,1.31-.91q1.49.33.82,1.51a10.82,10.82,0,0,1-6.75,6.35c-1.36.46-.78,2.64.6,2.17a13.37,13.37,0,0,0,6.42-4.53c1.33-1.74,3.23-4.81,1.43-6.84s-4.4-.41-5.87,1.1a11.3,11.3,0,0,0-3.62,7.42c-.06,1.45,2.19,1.45,2.25,0Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M825.18,805q-6.81-.39-13.62-.48a.38.38,0,0,0,0,.75q6.82.09,13.62.48c.48,0,.48-.72,0-.75Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M812.5,815.63h-8.92a.38.38,0,0,0,0,.75h8.92a.38.38,0,0,0,0-.75Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M806.16,831.58a84.62,84.62,0,0,1-8.93-.14c-.48,0-.48.72,0,.75a84.62,84.62,0,0,0,8.93.14c.48,0,.48-.77,0-.75Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M787.37,846.89a36.12,36.12,0,0,1-8,0c-.48-.05-.48.7,0,.75a36.12,36.12,0,0,0,8,0c.47-.06.48-.81,0-.75Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                    </g>\n' +
    '                    <g id="Läufer_2" data-name="Läufer 2">\n' +
    '                        <path d="M964,813.6a3.43,3.43,0,0,1-2.16-3.87c.37-1.53,2.14-3.11,3.73-2.24,2.79,1.53.93,5.56-.85,7-1.12.91.48,2.5,1.59,1.59a7.44,7.44,0,0,0,2.82-6.61c-.29-2.23-1.89-4.44-4.31-4.5a5.46,5.46,0,0,0-5.2,4.4,5.62,5.62,0,0,0,3.25,6.15c1.3.61,2.45-1.33,1.14-1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M961.09,814.13c-6.44-1.69-11.62-5.7-16.72-9.81-1.12-.9-2.72.68-1.59,1.59,5.4,4.35,10.9,8.6,17.71,10.39a1.13,1.13,0,0,0,.6-2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M968.56,815.43c2.69-1.56,5.31-3.2,7-5.92s2.57-6.31,3.32-9.49c.34-1.41-1.83-2-2.17-.6a36.36,36.36,0,0,1-2.65,8.14c-1.44,2.8-4,4.39-6.61,5.92a1.13,1.13,0,0,0,1.14,1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M962.32,817.42c-1,5.62,1.8,11.69,3.3,17a1.13,1.13,0,0,0,2.17-.6c-1.33-4.76-4.17-10.89-3.3-15.85.25-1.42-1.92-2-2.17-.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M964.45,836.83c-1.86,3.31-3.54,6.68-5.3,10-1.36,2.59-3.73,3.24-6.09,4.75s-4.64,3.26-6.88,5a1.15,1.15,0,0,0-.23,1.77l1.87,2.1c1,1.08,2.55-.51,1.59-1.59l-1.87-2.1-.23,1.77a88.36,88.36,0,0,1,7.53-5.41c1.61-1,4.13-1.68,5.39-3.09s1.69-4,2.52-5.65c1.12-2.19,2.44-4.28,3.64-6.43a1.13,1.13,0,0,0-1.94-1.14Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M967.2,836.58c2.88,1.14,6,1.57,8.87,2.58,2.36.82,2.49,1.08,2,3.54-.34,1.71-.9,3.38-1.32,5.07-.49,1.94-1.34,4.81-.56,6.81s3.27,2.07,5.27,2.48c1.41.29,2-1.88.6-2.17-2.51-.51-4.12-1.32-3.64-4.29.25-1.53.73-3,1.13-4.51s1.86-5.23,1-6.89c-1.64-3.27-9.7-3.57-12.79-4.79-1.35-.53-1.93,1.64-.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M941.18,809q-6.81-.39-13.62-.48a.38.38,0,0,0,0,.75q6.82.09,13.62.48c.48,0,.48-.72,0-.75Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M955.5,820.63h-8.92a.38.38,0,0,0,0,.75h8.92a.38.38,0,0,0,0-.75Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M957.16,832.58a84.62,84.62,0,0,1-8.93-.14c-.48,0-.48.72,0,.75a84.62,84.62,0,0,0,8.93.14c.48,0,.48-.77,0-.75Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M949.16,849.58a84.62,84.62,0,0,1-8.93-.14c-.48,0-.48.72,0,.75a84.62,84.62,0,0,0,8.93.14c.48,0,.48-.77,0-.75Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                    </g>\n' +
    '                    <g id="Party_1" data-name="Party 1">\n' +
    '                        <path d="M1106,810.6a3.43,3.43,0,0,1-2.16-3.87c.37-1.53,2.14-3.11,3.73-2.24,2.79,1.53.93,5.56-.85,7-1.12.91.48,2.5,1.59,1.59a7.44,7.44,0,0,0,2.82-6.61c-.29-2.23-1.89-4.44-4.31-4.5a5.46,5.46,0,0,0-5.2,4.4,5.62,5.62,0,0,0,3.25,6.15c1.3.61,2.45-1.33,1.14-1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1103.09,811.13c-6.44-1.69-11.62-5.7-16.72-9.81-1.12-.9-2.72.68-1.59,1.59,5.4,4.35,10.9,8.6,17.71,10.39a1.13,1.13,0,0,0,.6-2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1110.56,812.43c2.69-1.56,5.31-3.2,7-5.92s2.57-6.31,3.32-9.49c.34-1.41-1.83-2-2.17-.6a36.36,36.36,0,0,1-2.65,8.14c-1.44,2.8-4,4.39-6.61,5.92a1.13,1.13,0,0,0,1.14,1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1104.32,814.42c-1,5.62,1.8,11.69,3.3,17a1.13,1.13,0,0,0,2.17-.6c-1.33-4.76-4.17-10.89-3.3-15.85.25-1.42-1.92-2-2.17-.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1107.32,832.48c-.22,2-.49,4-.69,6s.24,4.25-.14,6c-.44,2.08-2.56,4.05-3.76,5.81-1.64,2.39-3,4.94-4.45,7.47a1.14,1.14,0,0,0,.4,1.54l2.55,1.19c1.3.61,2.45-1.33,1.14-1.94l-2.55-1.19.4,1.54c1.41-2.53,2.81-5.08,4.45-7.47,1-1.42,3.48-3.62,4-5.48s-.07-4.24.06-6.05c.18-2.5.55-5,.82-7.49.16-1.44-2.1-1.43-2.25,0Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1109.2,833.58c2.88,1.14,6,1.57,8.87,2.58,2.36.82,2.49,1.08,2,3.54-.34,1.71-.9,3.38-1.32,5.07-.49,1.94-1.34,4.81-.56,6.81s3.27,2.07,5.27,2.48c1.41.29,2-1.88.6-2.17-2.51-.51-4.12-1.32-3.64-4.29.25-1.53.73-3,1.13-4.51s1.86-5.23,1-6.89c-1.64-3.27-9.7-3.57-12.79-4.79-1.35-.53-1.93,1.64-.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1209.81,811.54a5.92,5.92,0,0,0,2.71-6c-.31-2.16-1.93-4.68-4.37-4.6-2.14.07-3.28,2.22-3.59,4.09a8.27,8.27,0,0,0,2.24,7c1,1,2.6-.55,1.59-1.59-1.4-1.45-2.7-5.07-.92-6.79,1.31-1.26,2.52.73,2.78,1.91a3.72,3.72,0,0,1-1.58,4,1.13,1.13,0,0,0,1.14,1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1211.66,812.3c5.72-1.89,10-6.19,14.13-10.39,1-1-.57-2.63-1.59-1.59-3.84,3.9-7.81,8.05-13.14,9.81-1.37.45-.78,2.63.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1208.21,814c.7,5.06-1.52,10.92-2.61,15.85-.31,1.41,1.86,2,2.17.6,1.21-5.47,3.4-11.4,2.61-17-.2-1.43-2.37-.82-2.17.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1205.77,831.48c.19,2.26.45,4.51.6,6.77.14,2.11-.39,4.74.1,6.77.4,1.66,2,3.31,2.81,4.77,1.49,2.63,2.71,5.41,3.93,8.17l.4-1.54-2,1.19a1.13,1.13,0,0,0,1.14,1.94l2-1.19a1.16,1.16,0,0,0,.4-1.54c-1-2.27-2-4.54-3.15-6.74s-3-4.16-3.33-6.53c-.59-3.83-.32-8.16-.65-12.07-.12-1.43-2.37-1.44-2.25,0Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1205.49,830.53c-2.87,1.4-9.2,1.85-10.07,5.44-.47,1.92.6,4.86,1,6.7a37.15,37.15,0,0,1,.86,4.72c.19,2.26-.54,2.94-2.81,3.52a1.13,1.13,0,0,0,.6,2.17c2-.51,3.57-.53,4.33-2.48s-.06-4.86-.45-6.81c-.49-2.48-2.47-6.67-.24-8.33s5.52-1.8,7.91-3c1.3-.64.16-2.58-1.14-1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1206.19,812.33c-5.08-.05-10.55-2-11.62-7.57-.27-1.42-2.44-.82-2.17.6,1.25,6.55,7.75,9.16,13.79,9.22a1.13,1.13,0,0,0,0-2.25Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1145.31,802.74c-4.13,1.26-4.4,8.65-.05,9.85a5.61,5.61,0,0,0,5.48-2.37c1.31-2.15-.15-5-.86-7.07-.46-1.37-2.63-.78-2.17.6.38,1.14.87,2.3,1.14,3.48.33,1.46-.19,2.24-1.52,2.88-3.3,1.59-4-4.4-1.42-5.19,1.38-.42.79-2.59-.6-2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1145.75,814.06a25.43,25.43,0,0,0,5,15.46c.81,1.19,2.76.07,1.94-1.14-3.12-4.6-4.67-8.71-4.65-14.33a1.13,1.13,0,0,0-2.25,0Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1150.61,816c2.51.95,4.6,2.48,7.37,1.69,3.41-1,6.79-2.92,10.05-4.33,1.33-.57.18-2.51-1.14-1.94-2.67,1.15-5.34,2.32-8,3.46-3.05,1.31-4.82,0-7.67-1.05-1.36-.51-1.94,1.66-.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1143,816c-1.4,2.34-3.59,5.17-4.28,7.83-.58,2.23,1.94,3.6,3.36,4.89,1.07,1,2.67-.61,1.59-1.59-.53-.48-2.45-1.71-2.61-2.38s.47-1.56.85-2.27c1-1.81,2-3.59,3-5.35A1.13,1.13,0,0,0,1143,816Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1154.6,832.24a75.35,75.35,0,0,0,11.08,2.68l-.83-1.08c-.35,4.29-1.76,8.4-1.38,12.74a1.16,1.16,0,0,0,.83,1.08l1.25.25c1.41.28,2-1.88.6-2.17l-1.25-.25.83,1.08c-.38-4.32,1-8.47,1.38-12.74a1.12,1.12,0,0,0-.83-1.08,75.35,75.35,0,0,1-11.08-2.68c-1.38-.45-2,1.73-.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1150.79,833.35q-.79,4.42-1.7,8.82a1.15,1.15,0,0,0,.79,1.38,104.23,104.23,0,0,1,11.68,3.62l-.79-.79.34,1.19a1.13,1.13,0,0,0,2.17-.6c-.63-2.25-1.78-2.26-4-3-2.89-1-5.83-1.84-8.8-2.58l.79,1.38q.91-4.4,1.7-8.82c.25-1.42-1.92-2-2.17-.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1162,850.06l.7-2.48a1.13,1.13,0,0,0-2.17-.6l-.7,2.48a1.13,1.13,0,0,0,2.17.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1240.52,806.79a25.52,25.52,0,0,1,1.56,4.82c.24,1.11.23,2.39,1.19,3.16a3.75,3.75,0,0,0,2.61.6c1.73-.06,3.47-.26,5.2-.4,1.43-.11,1.45-2.36,0-2.25l-3.72.28c-1.57.12-2.42.36-3-1.33-.67-2-1.06-4-1.92-6-.58-1.32-2.52-.18-1.94,1.14Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1238.22,809.37a26.88,26.88,0,0,0,4.6,9.46,2.53,2.53,0,0,0,2.66,1.11,7.5,7.5,0,0,0,2.09-1.24,12.92,12.92,0,0,1,3.32-1.46,1.13,1.13,0,0,0-.6-2.17,15.14,15.14,0,0,0-3.18,1.28c-.49.27-1.09.95-1.6,1.06-.8.17-1.27-.51-1.78-1.21a24.18,24.18,0,0,1-3.34-7.42,1.13,1.13,0,0,0-2.17.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1251.27,802.31a5.09,5.09,0,0,0-2.76,5.46c.27,1.88,1.6,4.6,3.71,4.92s3.77-1.55,4-3.51a10.57,10.57,0,0,0-2.29-6.92c-.86-1.14-2.81,0-1.94,1.14,1,1.37,3.17,5.15,1.51,6.72-1.43,1.34-2.68-1.85-2.77-2.88a2.89,2.89,0,0,1,1.72-3c1.31-.61.17-2.55-1.14-1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1250.87,816.12c-1.46,3.5.85,8.39,2.47,11.5.67,1.28,2.61.15,1.94-1.14-1.31-2.52-3.42-6.93-2.24-9.76.56-1.33-1.62-1.92-2.17-.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1251.12,829.79c-1.51,3.36-2.92,6.78-2.32,10.53.64,4,2.75,8.09,4.72,11.59l.67-1.65-3.25.47c-1.43.21-.82,2.38.6,2.17l3.25-.47a1.13,1.13,0,0,0,.67-1.65,44.06,44.06,0,0,1-3.87-8.62c-1.28-4.17-.23-7.42,1.48-11.23.59-1.31-1.35-2.46-1.94-1.14Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1253.48,831.46c.47,3,.27,7.48,2.46,9.88,2.41,2.64,6,4.38,8.66,6.94a1.17,1.17,0,0,0,1.36.18c-.9.42-.17-.26-.36.12-.07.13,1.08,0,.9.05l-1-1.69a10.63,10.63,0,0,0-2.06,6c-.1,1.45,2.15,1.44,2.25,0a8.46,8.46,0,0,1,1.75-4.84,1.13,1.13,0,0,0-1-1.69l0,.14a1.19,1.19,0,0,0-1.36-.18c.66-.31-4.61-4.47-5.1-4.81-3.84-2.72-3.77-6.38-4.44-10.68-.22-1.43-2.39-.82-2.17.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                    </g>\n' +
    '                    <g id="Party_2" data-name="Party 2">\n' +
    '                        <path d="M1107,810.6a3.43,3.43,0,0,1-2.16-3.87c.37-1.53,2.14-3.11,3.73-2.24,2.79,1.53.93,5.56-.85,7-1.12.91.48,2.5,1.59,1.59a7.44,7.44,0,0,0,2.82-6.61c-.29-2.23-1.89-4.44-4.31-4.5a5.46,5.46,0,0,0-5.2,4.4,5.62,5.62,0,0,0,3.25,6.15c1.3.61,2.45-1.33,1.14-1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1104.09,811.13c-6.44-1.69-11.62-5.7-16.72-9.81-1.12-.9-2.72.68-1.59,1.59,5.4,4.35,10.9,8.6,17.71,10.39a1.13,1.13,0,0,0,.6-2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1111.56,812.43c6-3.46,9.13-8.06,11-14.63a1.13,1.13,0,0,0-2.17-.6,33.91,33.91,0,0,1-2.8,7c-1.68,2.85-4.4,4.71-7.2,6.33a1.13,1.13,0,0,0,1.14,1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1105.32,814.42c-.56,3.11.16,6.22,0,9.36-.17,2.73-.49,5.32.12,8,.32,1.41,2.49.81,2.17-.6-.57-2.5-.19-4.91,0-7.42.17-2.92-.56-5.89,0-8.76.25-1.42-1.91-2-2.17-.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1105.38,832.5c-.09,3.83,2,7.47,2,11.17,0,2.5-2.26,4.72-3.61,6.69-1.64,2.39-3,4.94-4.45,7.47a1.14,1.14,0,0,0,.4,1.54l2.55,1.19c1.3.61,2.45-1.33,1.14-1.94l-2.55-1.19.4,1.54c1.41-2.53,2.81-5.08,4.45-7.47.92-1.34,3.54-3.66,4-5.48,1.07-4-2.16-9.33-2.06-13.52a1.13,1.13,0,0,0-2.25,0Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1106.2,833.58c2.48,1,5.09,1.65,7.64,2.45,1.27.39,3.8.82,4.83,1.73,1.19,1.05.76,2.33.61,4.06-.23,2.76-1.39,7.16-.09,9.77,1,2.1,3.09,2,5.27,2.48,1.41.29,2-1.88.6-2.17-2.43-.5-3.74-1.57-4-4.21a29.42,29.42,0,0,1,.41-5.22c.18-2.06.88-4.62-.69-6.12s-4.53-1.92-6.34-2.49c-2.55-.79-5.16-1.43-7.64-2.45-1.34-.55-1.92,1.63-.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1210.81,811.54a5.92,5.92,0,0,0,2.71-6c-.31-2.16-1.93-4.68-4.37-4.6-2.14.07-3.28,2.22-3.59,4.09a8.27,8.27,0,0,0,2.24,7c1,1,2.6-.55,1.59-1.59-1.4-1.45-2.7-5.07-.92-6.79,1.31-1.26,2.52.73,2.78,1.91a3.72,3.72,0,0,1-1.58,4,1.13,1.13,0,0,0,1.14,1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1212.66,812.3c5.67-1.93,7.79-7.94,11.63-12,1-1.05-.59-2.65-1.59-1.59-3.45,3.64-5.6,9.71-10.64,11.42-1.36.46-.78,2.64.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1209.21,814c.7,5.06-1.52,10.92-2.61,15.85-.31,1.41,1.86,2,2.17.6,1.21-5.47,3.4-11.4,2.61-17-.2-1.43-2.37-.82-2.17.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1206.77,831.48c.19,2.26.45,4.51.6,6.77.14,2.11-.39,4.74.1,6.77.4,1.66,2,3.31,2.81,4.77,1.49,2.63,2.71,5.41,3.93,8.17l.4-1.54-2,1.19a1.13,1.13,0,0,0,1.14,1.94l2-1.19a1.16,1.16,0,0,0,.4-1.54c-1-2.27-2-4.54-3.15-6.74s-3-4.16-3.33-6.53c-.59-3.83-.32-8.16-.65-12.07-.12-1.43-2.37-1.44-2.25,0Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1206.49,830.53c-2.14,1.48-3.7,3.66-5.28,5.69-1.37,1.76-4.23,4.13-4.84,6.28-.75,2.64,1.58,6.59,2.47,9,.37,1,2.26,5,1.62,6.1s-2.74.81-4,.8a1.13,1.13,0,0,0,0,2.25c1.68,0,4.6.36,5.8-1.33s-.2-5.39-.82-7.16-1.49-3.73-2.1-5.65c-.76-2.38-.73-3.13.64-5.11,2.09-3,4.56-6.82,7.6-8.91,1.18-.82.06-2.77-1.14-1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1207.19,812.33c-1.59.19-2.28.9-3.57,1.7s-2.22,0-3.69.27c-1.14.19-7.54,3.22-7.35,3.91a1.13,1.13,0,0,0-2.17.6c.87,3.16,4.9.25,6.42-.63a15.88,15.88,0,0,1,2.69-1.41c1-.36,1.45,0,2.44.11,2.38.21,3-2,5.23-2.3,1.42-.17,1.44-2.42,0-2.25Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1146.31,802.74c-4.13,1.26-4.4,8.65-.05,9.85a5.61,5.61,0,0,0,5.48-2.37c1.31-2.15-.15-5-.86-7.07-.46-1.37-2.63-.78-2.17.6.38,1.14.87,2.3,1.14,3.48.33,1.46-.19,2.24-1.52,2.88-3.3,1.59-4-4.4-1.42-5.19,1.38-.42.79-2.59-.6-2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1146.75,814.06a25.43,25.43,0,0,0,5,15.46c.81,1.19,2.76.07,1.94-1.14-3.12-4.6-4.67-8.71-4.65-14.33a1.13,1.13,0,0,0-2.25,0Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1151.61,816c2.79,1.05,5,2.68,8,1.44,2.82-1.17,4.74-3.63,7.47-5,1.3-.64.16-2.58-1.14-1.94-2.12,1-3.62,2.69-5.57,4-3.07,2-5.19.45-8.16-.67-1.36-.51-1.94,1.66-.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1144,816c-1.54,2.59-3.71,5.5-4.39,8.45a4.61,4.61,0,0,0-.07,1.63,50.67,50.67,0,0,1-.15,5.43c0,1.45,2.2,1.45,2.25,0s.41-3,.38-4.54c0-.52-.28-1.52-.27-1.71.11-2.57,2.9-5.94,4.19-8.12A1.13,1.13,0,0,0,1144,816Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1155.6,832.24c3.42,1.1,8.18,1.33,11.18,3.18,2.61,1.61,5.49,2.5,8.15,4a1.15,1.15,0,0,0,1.36-.18c.77-.81.35-2.32,1-3,1-1-.59-2.64-1.59-1.59-.77.81-.35,2.32-1,3l1.36-.18a27,27,0,0,0-4-1.81c-1.81-.69-2.89-2.05-4.5-2.86-1.23-.62-3.12-.6-4.48-.9-2.33-.51-4.61-1.16-6.88-1.89-1.38-.44-2,1.73-.6,2.17Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1151.79,833.35c-1.33,5-3.72,8.48-3.69,13.86a46.26,46.26,0,0,0,.51,6.39c.43,2.94,1.34,3.5-2.11,3.78-1.43.12-1.45,2.37,0,2.25s3.57-.2,4.8-1.33a1.14,1.14,0,0,0,.29-1.09,41.21,41.21,0,0,1-1.21-11.59c.26-4.27,2.53-7.63,3.59-11.67a1.13,1.13,0,0,0-2.17-.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1241.52,806.79a25.52,25.52,0,0,1,1.56,4.82c.24,1.11.23,2.39,1.19,3.16a3.75,3.75,0,0,0,2.61.6c1.73-.06,3.47-.26,5.2-.4,1.43-.11,1.45-2.36,0-2.25l-3.72.28c-1.57.12-2.42.36-3-1.33-.67-2-1.06-4-1.92-6-.58-1.32-2.52-.18-1.94,1.14Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1237.42,812.8c.92,3,2.89,7.26,6,8.48a2.37,2.37,0,0,0,2.75-.37c2-1.52,3.2-2.94,5.75-3.69a1.13,1.13,0,0,0-.6-2.17,14.45,14.45,0,0,0-3.86,1.69c-.84.55-1.63,1.83-2.48,2.21-2.28,1-4.85-5-5.38-6.76-.42-1.38-2.6-.79-2.17.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1252.27,802.31a5.09,5.09,0,0,0-2.76,5.46c.27,1.88,1.6,4.6,3.71,4.92s3.77-1.55,4-3.51a10.57,10.57,0,0,0-2.29-6.92c-.86-1.14-2.81,0-1.94,1.14,1,1.37,3.17,5.15,1.51,6.72-1.43,1.34-2.68-1.85-2.77-2.88a2.89,2.89,0,0,1,1.72-3c1.31-.61.17-2.55-1.14-1.94Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1251.87,816.12c-1.46,3.5.85,8.39,2.47,11.5.67,1.28,2.61.15,1.94-1.14-1.31-2.52-3.42-6.93-2.24-9.76.56-1.33-1.62-1.92-2.17-.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1252.12,829.79c-1.51,3.36-2.92,6.78-2.32,10.53.64,4,2.75,8.09,4.72,11.59l.67-1.65-3.25.47c-1.43.21-.82,2.38.6,2.17l3.25-.47a1.13,1.13,0,0,0,.67-1.65,44.06,44.06,0,0,1-3.87-8.62c-1.28-4.17-.23-7.42,1.48-11.23.59-1.31-1.35-2.46-1.94-1.14Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                        <path d="M1252.48,832.46c.47,3,.27,7.48,2.46,9.88,2.41,2.64,6,4.38,8.66,6.94a1.17,1.17,0,0,0,1.36.18c-.9.42-.17-.26-.36.12-.07.13,1.08,0,.9.05l-1-1.69a10.63,10.63,0,0,0-2.06,6c-.1,1.45,2.15,1.44,2.25,0a8.46,8.46,0,0,1,1.75-4.84,1.13,1.13,0,0,0-1-1.69l0,.14a1.19,1.19,0,0,0-1.36-.18c.66-.31-4.61-4.47-5.1-4.81-3.84-2.72-3.77-6.38-4.44-10.68-.22-1.43-2.39-.82-2.17.6Z"\n' +
    '                              transform="translate(6.76 -705.82)"/>\n' +
    '                    </g>\n' +
    '                </svg>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</footer>');
}]);
})();
