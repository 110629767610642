/*global angular */

/**
 * The main controller for the app. The controller:
 * - retrieves and persists the model via the todoStorage service
 * - exposes the model to the template and provides event handlers
 */
//angular = require('angular');

angular.module('todomvc')
    .controller('InvitationCtrl', function TodoCtrl($scope, $routeParams, $filter, $translate) {
        'use strict';

        var vm = this;

        vm.changeLanguage = function (langKey) {
            $translate.use(langKey);
        };
    });
