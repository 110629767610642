/*global angular */

/**
 * The main TodoMVC app module
 *
 * @type {angular.Module}
 */

angular = require('angular');
require('angular-route');
require('../dist/templateCachePartials');
require('angular-translate');
require('angular-translate-loader-static-files');

angular.module('todomvc', ['ngRoute', 'todoPartials', 'pascalprecht.translate'])
    .config(function ($routeProvider) {
        'use strict';

        var routeConfig = {
            controller: 'InvitationCtrl',
            controllerAs: 'vm',
            templateUrl: '/partials/invitation.html'
        };

        $routeProvider
            .when('/', routeConfig)
            .when('/:status', routeConfig)
            .otherwise({
                redirectTo: '/'
            });
    })
    .config(['$translateProvider', function ($translateProvider) {
        $translateProvider.useStaticFilesLoader({
            prefix: '../languages/locale-',
            suffix: '.json'
        });

        $translateProvider.preferredLanguage('de');
    }]);

require('invitationCtrl');
